import { OrderDVDto } from '@sortera/dvfv-types'

import {
  FA_BAGS,
  FA_CONTAINER,
  KARL,
  KRANBIL,
  PLOCKSERVICE,
  SPECIALARE,
  SPECIALARE_CONTAINER,
} from '@/utils/orderCategory.constants'

export function specialish(o: OrderDVDto): boolean {
  return specialare(o) || specialareContainer(o)
}

export function plockservice(o: OrderDVDto): boolean {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === PLOCKSERVICE)
}

export function crane(o: OrderDVDto): boolean {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === KRANBIL)
}

export function faBags(o: OrderDVDto): boolean {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === FA_BAGS)
}

export function faContainer(o: OrderDVDto): boolean {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === FA_CONTAINER)
}

export function receptacle(o: OrderDVDto): boolean {
  return Boolean(
    o.orderCategory.orderCategoryName.toLowerCase() === KARL &&
      o.receptacleQuantities &&
      o.receptacleQuantities.length > 0
  )
}

export function bagDelivery(o: OrderDVDto): boolean {
  return Boolean(
    o.orderCategory.orderCategoryName.toLowerCase().includes('lev') &&
      o.bagDeliveryQuantities?.prefix
  )
}

export function LD(o: OrderDVDto): boolean {
  const category = o.orderCategory.orderCategoryName.toLowerCase()
  return Boolean(category === 'ld' && o.bookingActionType)
}

export function LVX(o: OrderDVDto): boolean {
  const category = o.orderCategory.orderCategoryName.toLowerCase()
  return Boolean(category === 'lvx' && o.bookingActionType)
}

export function specialare(o: OrderDVDto): boolean {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === SPECIALARE)
}

export function specialareContainer(o: OrderDVDto): boolean {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === SPECIALARE_CONTAINER)
}

export function container(o: OrderDVDto): boolean {
  return Boolean(o.bookingActionType)
}

export function head(o: OrderDVDto): boolean {
  return Boolean(!o.subOrderCode && o.subOrderCodes && o.subOrderCodes.length > 0)
}

export function sub(o: OrderDVDto): boolean {
  return Boolean(o.subOrderCode && o.parentOrderId > 0)
}
