export const SORTERA = 'sortera' as const
export const SORTERA_GOTEBORG = 'sorteragoteborg' as const
export const SORTERA_STOCKHOLM = 'sorterastockholm' as const
export const BIG_BAG = 'bigbag' as const
export const SORTERA_MALMO = 'sorteramalmo' as const
export const AKERISACKEN = 'akerisacken' as const
export const SORTERA_OREBRO = 'sorteraorebro' as const

export const COMPANY_CODES = [
  SORTERA_GOTEBORG,
  SORTERA_STOCKHOLM,
  BIG_BAG,
  SORTERA_MALMO,
  AKERISACKEN,
  SORTERA_OREBRO,
]

export type CompanyCode = (typeof COMPANY_CODES)[number]

export const COMPANIES = [
  { id: 1, code: SORTERA_GOTEBORG, name: 'Sortera Göteborg' },
  { id: 2, code: SORTERA_STOCKHOLM, name: 'Sortera Stockholm' },
  { id: 3, code: BIG_BAG, name: 'BigBag' },
  { id: 4, code: SORTERA_MALMO, name: 'Sortera Malmö' },
  { id: 5, code: AKERISACKEN, name: 'Åkerisäcken' },
  { id: 6, code: SORTERA_OREBRO, name: 'Sortera Örebro' },
]

export function stringToCompanyCode(value: string): CompanyCode {
  if (COMPANY_CODES.every(code => code !== value)) {
    throw new Error(`${value} is not a valid CompanyCode`)
  }

  return value as CompanyCode
}
