import { initializeApp } from 'firebase/app'
import { collection, getFirestore } from 'firebase/firestore'

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  // Prevent cross-domain storage issues by hosting auth helpers ourselves:
  // https://firebase.google.com/docs/auth/web/redirect-best-practices#self-host-helper-code
  authDomain: window.location.host,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
}
export const firebaseApp = initializeApp(config)
const db = getFirestore(firebaseApp)

export const settingsRef = collection(db, 'settings')
export const entriesRef = collection(db, 'entries')
export const parkedRef = collection(db, 'parked')
export const vehiclesRef = collection(db, 'vehicles')
export const vehicleStationsRef = collection(db, 'vehicleStations')
export const orderCategoriesRef = collection(db, 'orderCategories')
export const markingsRef = collection(db, 'markings')
