<script setup>
import { computed, ref } from 'vue'

const emit = defineEmits(['update:modelValue', 'remove'])

const props = defineProps({
  modelValue: {
    required: true,
    type: Array,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  hideDelete: {
    default: false,
    type: Boolean,
  },

  skeletons: {
    default: 0,
    type: Number,
  },
  alt: {
    default: 'Bild',
    type: String,
  },
})

const selectedIndex = ref(null)

const skeletonArray = computed(() => new Array(props.skeletons))
const valueWrap = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

function remove() {
  const copy = JSON.parse(JSON.stringify(valueWrap.value))
  copy.splice(selectedIndex.value, 1)

  valueWrap.value = copy

  emit('remove', selectedIndex.value)
  selectedIndex.value = null
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <div
      class="input-bordered flex max-h-40 flex-wrap items-start gap-2 overflow-x-auto rounded-box border bg-base-100 p-2 shadow-inner"
    >
      <div v-for="(image, i) in valueWrap" :key="i" class="flex">
        <button
          class="btn btn-square btn-ghost h-16 rounded p-px ring-offset-1 dark:!bg-secondary"
          :class="{
            'ring-2 ring-base-content': selectedIndex === i,
          }"
          @click="selectedIndex = i"
        >
          <img
            :alt="alt"
            class="h-full w-full rounded-md bg-base-200 object-contain"
            :src="image + '?tr=h-50,c-at_max,q-10'"
          />
        </button>
      </div>
      <button
        v-for="(_, i) in skeletonArray"
        :key="i"
        class="btn btn-square btn-ghost h-16 animate-pulse rounded dark:!bg-secondary"
        disabled
      />
    </div>
    <div
      v-if="selectedIndex !== null"
      class="relative flex flex-col gap-4 border border-gray-600 bg-base-100 p-4 shadow-inner md:flex-row"
    >
      <button
        class="btn btn-circle btn-outline btn-sm absolute -right-4 -top-4 border-gray-600 bg-base-200"
        @click="selectedIndex = null"
      >
        <BaseIcon class="h-5 w-5" name="close" />
      </button>
      <div class="flex h-72 w-full">
        <img
          :alt="alt"
          class="w-full rounded-lg border border-base-300 bg-base-200 object-contain shadow"
          :src="modelValue[selectedIndex] + '?tr=h-288,c-at_max,q-30'"
        />
      </div>
      <div class="flex w-full flex-col gap-4">
        <a class="btn btn-primary" :href="modelValue[selectedIndex] + '?pr-true'" target="_blank">
          {{ $t('image-upload.show-original') }}
        </a>
        <button
          v-if="!props.hideDelete"
          class="btn btn-error"
          :class="{ disabled: disabled }"
          @click="remove"
        >
          {{ $t('image-upload.delete') }}
        </button>
      </div>
    </div>
  </div>
</template>
