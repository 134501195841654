<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import HeadwayWidget from '@/components/headway-widget.vue'
import Notifications from '@/components/notifications.vue'
import { useAuth } from '@/composables/auth'
import { ROUTES } from '@/router/constants'
import { useAppStore } from '@/stores/app'
import { isDev } from '@/utils/env'
import { getSavedState } from '@/utils/local-storage'

const props = defineProps({
  title: {
    default: '',
    type: String,
  },
  showBackBtn: {
    default: false,
    type: Boolean,
  },
})

const route = useRoute()
const router = useRouter()
const store = useAppStore()
const { currentUserSpaced, IsEdge, IsDeveloper } = storeToRefs(store)
const { logout } = useAuth()

function iconClick() {
  if (props.showBackBtn) {
    if (route.name === ROUTES.PARKED_LIST)
      router.push({ name: ROUTES.ORDER_LIST }) // TODO: this feels like a hack, fix it
    else router.back()
  } else router.push({ name: ROUTES.HOME })
}

const currentVersion = computed(() => getSavedState('lastVersion'))
</script>

<template>
  <nav class="sticky top-0 z-20 mx-auto w-full border-b border-base-200 bg-base-100 shadow">
    <BaseModal id="helpModal" :mobileLike="false" :title="$t('help.title')">
      <div class="collapse collapse-plus bg-base-200">
        <input type="checkbox" />
        <div class="collapse-title font-medium">
          {{ $t('help.legends.title') }}
        </div>
        <div class="collapse-content">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>{{ $t('help.legends.icon-column') }}</th>
                <th>{{ $t('help.legends.description-column') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  <BaseIcon name="mobile-alt" />
                </td>
                <td>{{ $t('help.legends.notified') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="truck" />
                </td>
                <td>{{ $t('help.legends.on-board') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="check" />
                </td>
                <td>{{ $t('help.legends.performed') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="exclamation-circle" />
                </td>
                <td>{{ $t('help.legends.littera-notes') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="exclamation-triangle" />
                </td>
                <td>{{ $t('help.legends.important-information') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="weight-hanging" />
                </td>
                <td>{{ $t('help.legends.heavy') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="industry" />
                </td>
                <td>{{ $t('help.legends.order-facility') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="building" />
                </td>
                <td>{{ $t('help.legends.client-name') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="id-badge" />
                </td>
                <td>{{ $t('help.legends.sales-person') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="user-circle" />
                </td>
                <td>{{ $t('help.legends.contact-on-site') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="user" />
                </td>
                <td>{{ $t('help.legends.client-contact') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="images" />
                </td>
                <td>{{ $t('help.legends.images') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="map-marked-alt" />
                </td>
                <td>{{ $t('help.legends.address') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="comment-alt" />
                </td>
                <td>{{ $t('help.legends.notes') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="directions" />
                </td>
                <td>{{ $t('help.legends.container-action') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="dumpster" />
                </td>
                <td>{{ $t('help.legends.container-type') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="eye" />
                </td>
                <td>{{ $t('help.legends.contents-material') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="bullseye" />
                </td>
                <td> {{ $t('help.legends.marking-to-look-for') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="rhombus" />
                </td>
                <td>{{ $t('help.legends.dangerous-material') }}</td>
              </tr>
              <tr>
                <td class="text-center">
                  <BaseIcon name="cubes" />
                </td>
                <td>{{ $t('help.legends.cubic-meters') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <span>{{ $t('help.version-title') }}</span>
        <div class="flex font-mono text-sm text-gray-500">{{ currentVersion }}</div>
      </div>
      <div class="flex flex-col">
        {{ $t('help.contact-us') }}
        <a
          class="text-sortera-500 underline hover:no-underline dark:text-sortera-600"
          href="mailto:dv@sortera.se"
        >
          dv@sortera.se
        </a>
      </div>
    </BaseModal>

    <div class="mx-auto flex flex-none items-center pl-2 sm:pl-4 lg:max-w-4xl xl:pl-6">
      <div
        class="logo-container group flex h-12 flex-none cursor-pointer items-center"
        @click="iconClick"
      >
        <a
          class="flex h-full items-center justify-center bg-sortera-500 px-3 text-gray-100 transition-all group-hover:px-2 group-hover:text-gray-200"
        >
          <BaseIcon
            :class="{ 'text-xl': showBackBtn, 'text-lg': !showBackBtn }"
            :name="showBackBtn ? 'left' : 'truck'"
          />
        </a>
      </div>
      <div class="flex h-12 flex-auto items-center justify-between">
        <div class="mx-1 min-w-0 flex-1 text-2xl"> {{ title.replace('#', '') }}</div>
        <div class="flex w-10 flex-shrink-0 items-center justify-end gap-6 sm:w-20 lg:w-60 xl:w-72">
          <div class="flex items-stretch gap-1 sm:gap-3">
            <Notifications />
            <HeadwayWidget id="changelog" account="7OdedJ">
              <div class="flex items-center" />
            </HeadwayWidget>
            <div class="dropdown dropdown-end">
              <label class="btn btn-square btn-ghost" tabindex="0">
                <BaseAvatar
                  class="h-8 w-8"
                  mask
                  :spacedName="
                    currentUserSpaced ??
                    // Needs some content so that the avatar is always visible.
                    // Important so that users can always find the menu options.
                    '?'
                  "
                />
              </label>
              <ul
                class="menu dropdown-content z-[1] rounded-box border bg-base-100 p-2 shadow"
                tabindex="0"
              >
                <li>
                  <a onclick="helpModal.showModal()">{{ $t('help.title') }}</a>
                </li>
                <li>
                  <a
                    href="https://www.sortera.se/sites/sortera/files/media/documents/recycling-tillstand-transport-avfall.pdf"
                    target="_blank"
                  >
                    Transporttillstånd
                    <BaseIcon class="ml-1 h-3" name="external-link" />
                  </a>
                </li>
                <li>
                  <RouterLink class="whitespace-nowrap" :to="{ name: ROUTES.SETTINGS }">
                    {{ $t('routes.settings') }}
                  </RouterLink>
                </li>
                <li v-if="IsEdge || isDev()">
                  <RouterLink class="whitespace-nowrap" :to="{ name: ROUTES.FLAGS }">
                    {{ $t('routes.flags') }}
                  </RouterLink>
                </li>
                <li v-if="IsDeveloper">
                  <RouterLink class="whitespace-nowrap" :to="{ name: ROUTES.TOOLS }">
                    {{ $t('routes.tools') }}
                  </RouterLink>
                </li>
                <li>
                  <a class="whitespace-nowrap" @click="logout">
                    {{ $t('auth.signout') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="less">
.logo-container {
  margin-left: -48px;

  &:before {
    @apply bg-sortera-500;
    content: '';
    display: inline-block;
    height: 100%;
    width: 48px;
    margin-left: -8px;
    mask-position: left;
    mask-repeat: no-repeat;
    mask-size: auto 100%;
    mask-image: url('../assets/header-ending-shape.svg');
    transform: rotateY(180deg);
  }

  &:after {
    @apply bg-sortera-500;
    content: '';
    display: inline-block;
    height: 100%;
    width: 48px;
    margin-left: -8px;
    mask-position: left;
    mask-repeat: no-repeat;
    mask-size: auto 100%;
    mask-image: url('../assets/header-ending-shape.svg');
  }
}
</style>
