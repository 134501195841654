import { RegionCode, ResourceAndAssignedOrdersDVDto } from '@sortera/dvfv-types'

import axios from '@/plugins/axios.api'

import { regionToCompany } from './regions'

export async function fetchCurrentTimeEpoch(): Promise<number> {
  return (await axios.get('/api/time/epoch')).data
}

/**
 * Fetches resources for a given region code.
 */
export async function fetchResources(
  regionCode: RegionCode
): Promise<ResourceAndAssignedOrdersDVDto[]> {
  const companyCode = regionToCompany(regionCode)
  return (await axios.get(`/api/resources?onlyActive=false&companyCode=${companyCode}`)).data
}

/**
 * Fetches orders from the server.
 */
export async function fetchOrders(
  regionCode: RegionCode,
  resource: string,
  timestamp: string
): Promise<ResourceAndAssignedOrdersDVDto[]> {
  const companyCode = regionToCompany(regionCode)
  return (
    await axios.get(
      `/api/resources?onlyActive=false&companyCode=${companyCode}&timestamp=${timestamp}&resourceCode=${encodeURIComponent(
        resource
      )}`
    )
  ).data
}
