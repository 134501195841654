export const ROUTES = {
  HOME: 'home',
  ADMIN: 'admin',
  ASSIGN: 'assign',
  PARKED_LIST: 'parked-list',
  PARKED_CONTAINER: 'parked-container',
  ORDER_LIST_BY_DATE: 'listWithDate',
  ORDER_LIST: 'list',
  ORDER: 'order',
  SETTINGS: 'settings',
  FLAGS: 'flags',
  TOOLS: 'tools',
  _404: '404',
  AUTH_MSAL: 'authMsal',
} as const
