export const PLANNED = 10 as const
export const NOTIFIED = 20 as const
export const ON_BOARD = 50 as const
export const RELOCATED = 80 as const
export const RELOCATED_ON_BOARD = 85 as const
// parked at facility
export const RECEIVED = 100 as const
// dumped at facility
export const PROCESSING = 150 as const
// digitally processed and ready for invoicing
export const PROCESSED = 200 as const
// digital flow does not exist yet, but order is ready for invoicing anyways
export const FAKE_PROCESSED = 201 as const
export const INVOICED = 300 as const

export const statuses = {
  PLANNED,
  NOTIFIED,
  ON_BOARD,
  RELOCATED,
  RELOCATED_ON_BOARD,
  RECEIVED,
  PROCESSING,
  PROCESSED,
  FAKE_PROCESSED,
  INVOICED,
}
